import { Component, ElementRef, Injector, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import DataGrid from 'devextreme/ui/data_grid';
import * as _moment from 'moment';
import { Moment } from 'moment';
import { forkJoin, map, Subscription } from 'rxjs';

// components
import { FullListComponent } from '../../shared/components/full-list/full-list.component';

// models
import { AuctionCluster } from '../../shared/models/auction-cluster';
import { ExportConstants } from '../../shared/constants/export.constants';
import { ApplicationSettings } from '../../shared/models/application-settings';
import { BuyerActionOnLotGroup, GenericLotColumn, GenericLotRow } from '../../shared/models/generic-lot';
import { BuyerLevelSubbuyer } from '../shared/models/buyer-level-subbuyer';
import { Catalog, CatalogsRequest, PrebidMinMaxAmountsEnum } from '../../shared/models/catalog';
import { DateRanges, Report, SystemReportDesignTypes } from '../../shared/models/report';
import { GridProperty, ProductProperty } from '../../shared/models/product';
import { LookupTable, LookupTableField } from '../../auction/shared/models/lookup-table';
import { Lot, LotProperty } from '../../shared/models/lot';
import { MasterData } from '../../shared/models/master-data';
import { PrebidOnLotComponent } from '../shared/components/prebids/prebid-on-lot.component';
import { ReportFilterModel } from '../../auction/shared/models/report-filter-model';
import { ProductPropertyTypeEnum } from '../../shared/enum/productPropertyTypeEnum';
import { UrlTypeEnum } from '../../shared/enum/urlTypeEnum';

// services
import { TranslateService } from '@ngx-translate/core';
import { ServiceFactory } from '../../shared/factory/service-factory';
import { AuthService } from '../../shared/services/auth.service';
import { TitleService } from '../../shared/services/title.service';
import { TokenService } from '../../shared/services/token.service';
import { WebApiService } from '../../shared/services/web-api.service';

import { AuctionClusterService } from '../shared/services/auction-cluster.service';
import { BuyerLevelReportService } from '../shared/services/report.service';
import { CatalogService } from '../shared/services/catalog.service';
import { LookupTableService } from '../shared/services/lookup-table.service';
import { MasterDataService } from '../shared/services/master-data.service';
import { PrebidOnProductComponent } from './prebid-on-product.component';
import { ProductService } from '../shared/services/product.service';
import { SupplyMonitorService } from '../shared/services/supply-monitor.service';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';

const moment = (_moment as any).default ? (_moment as any).default : _moment;
const NOON = 12;

@Component({
  selector: 'buyer-supply-monitor-component',
  templateUrl: 'buyer-supply-monitor.component.html',
  styleUrls: ['./buyer-supply-monitor.component.scss']
})
export class BuyerSupplyMonitorComponent extends FullListComponent<GenericLotRow, PrebidOnLotComponent> implements OnInit, OnDestroy {

  @ViewChild('detailsForm') detailsForm: any;
  @ViewChild('details') detailsComponent: PrebidOnLotComponent;
  @ViewChild('buyerSupplierMonitorGrid') supplyGrid: DxDataGridComponent;

  auctionClusterService: AuctionClusterService;
  catalogService: CatalogService;
  lookupTableService: LookupTableService; ppingListLotComponent;
  masterDataService: MasterDataService;
  productService: ProductService;

  auctionClusterId: number = null;
  secondRestrictionMasterDataId: number = null;
  buyerSecondRestrictionMasterDataRowIds: Array<number> = null;
  auctionClusters: Array<AuctionCluster> = [];
  catalogId: number = null;
  prebidsEnabled = false;
  prebidOnProductEnabled = false;
  catalogs: Array<Catalog> = [];
  date: Date = new Date();
  columns: Array<any> = [];
  subbuyers: Array<BuyerLevelSubbuyer> = [];
  data: Array<any> = new Array<any>();
  objectKeys: Array<string> = new Array<string>();
  items = [];
  filterPrebids = false;
  filterMarked = false;
  lastRefresh = '';
  currentPage = 1;
  lastRecordedPage = 1;
  dataGridInstance: DataGrid;

  exportConstants: ExportConstants = new ExportConstants();
  exportUrl: SafeUrl;
  exportFilename: string;
  @ViewChild('exportLink') exportLink: ElementRef;
  @ViewChild('prebids') prebids: PrebidOnProductComponent;

  tokenService: TokenService;

  genericFilters = '';

  productId: number;
  reportId: number;
  report: Report = new Report();
  allReports: Array<Report> = [];
  productProperties: Array<ProductProperty> = [];
  reportProperties: Array<GridProperty> = [];

  masterDataDropdowns: Array<any> = [];
  masterDataDropdownsFiltered: Array<any> = [];
  dropdownsData: Array<any> = [];
  comboBoxes: Array<any> = [];
  lookupTables: Array<LookupTable> = [];

  auctionId: number = null;

  reportName: string = '';

  filters: Array<any> = [];
  filterBindings: ReportFilterModel = new ReportFilterModel();
  dateFiltersCascadingDefinitions: any = {};

  newReportName: string;
  isSaveProfileDialogOpened: boolean;
  isLoadProfileDialogOpened: boolean;

  dateFilterItems = [];

  popUpShowed: boolean = false;
  popUpOpened: boolean = false;
  carrouselLoaded: boolean = false;
  carrouselData: Array<any> = [];
  isBuyerViewer: boolean = false;

  rtlEnabled = localStorage.getItem('last-selected-language-direction') ? JSON.parse(localStorage.getItem('last-selected-language-direction')) : false;
  private _subscription: Subscription;
  gridColumns = [];
  selectedLanguage: string;

  constructor(
    protected injector: Injector,
    protected appSettings: ApplicationSettings,
    protected route: ActivatedRoute,
    protected webApiService: WebApiService,
    private reportService: BuyerLevelReportService,
    private authenticationService: AuthService,
    private supplyMonitorService: SupplyMonitorService,
    private sanitizer: DomSanitizer,
    private titleService: TitleService,
    private translateService: TranslateService
  ) {
    super(injector, GenericLotRow);
    this.getServices(route, appSettings, webApiService);

    this.tokenService = this.injector.get(TokenService);

    this._subscription = this.language.direction.subscribe(dir => {
      this.rtlEnabled = dir;
    });
    this.selectedLanguage = localStorage.getItem('last-selected-language-code');
    this.translate.get(['TODAY', 'YESTERDAY', 'LAST_WEEK', 'RANGE']).subscribe((res: string) => {
      this.dateFilterItems = [
        {
          value: 0,
          name: res['TODAY']
        },
        {
          value: 1,
          name: res['YESTERDAY']
        },
        {
          value: 2,
          name: res['LAST_WEEK']
        },
        {
          value: 3,
          name: res['RANGE']
        }
      ];
    });
  }

  ngOnInit() {
    this.title.set('SUPPLY_MONITOR.TITLE');
    this.setTranslations('BUYER.SUPPLY_MONITOR');

    // Check if buyer doesn't contain purchase permission (BuyerPermissionEnum.BuyOnClock)
    this.isBuyerViewer = !this.tokenService.permissionMet('BuyerPermissions.2', this.id);

    this.spinner.show();
    forkJoin(
      this.auctionClusterService.getAuctionClustersPerLevelWithoutImage()
    ).subscribe(result => {
      this.auctionClusters = result[0];
      if (this.auctionClusters.length !== 0) {
        this.auctionClusterId = this.auctionClusters[0].auctionClusterId;
        this.secondRestrictionMasterDataId = this.auctionClusters[0].secondRestrictionMasterDataId;
        this.buyerSecondRestrictionMasterDataRowIds = this.auctionClusters[0].buyerSecondRestrictionMasterDataRowIds;
        this.filterCatalogs();
        this.getSubbuyers();

        this.getReportTemplates();
      }
      this.spinner.hide();
    },
      error => {
        this.errorService.show(error);
        this.spinner.hide();
      });
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this._subscription.unsubscribe();
  }

  getServices(route, appSettings, webApiService) {
    this.auctionClusterService = ServiceFactory.getAuctionClusterService(document.location.hash, route, appSettings, webApiService) as AuctionClusterService;
    this.catalogService = ServiceFactory.getCatalogService(route, appSettings, webApiService) as CatalogService;
    this.lookupTableService = ServiceFactory.getLookupTableService(route, appSettings, webApiService) as LookupTableService;
    this.masterDataService = ServiceFactory.getMasterDataService(document.location.hash, route, appSettings, webApiService) as MasterDataService;
    this.productService = ServiceFactory.getProductService(document.location.hash, route, appSettings, webApiService) as ProductService;
  }

  exportToCsv() {
    let csvString = '';
    let delimiter = ';';
    const currentLanguageCode = this.translateService.currentLang;

    this.language.getLanguages().subscribe(l => {
      const currentLanguge = l.filter(x => x.code === currentLanguageCode)[0]
      if (currentLanguge !== undefined) {
        delimiter = currentLanguge.csvDelimiter;
      }
    });

    this.columns.forEach(c => {
      csvString += `"${c.name}"${delimiter}`;
    });

    csvString += `
    `;

    this.data.forEach(d => {
      this.objectKeys.forEach(key => {
        // Remove line breaks for export
        let value = this.removeLineBreaks(d[key]);
        csvString += `"${value}"${delimiter}`;
      });
      csvString += `
      `;
    });

    const blob = new Blob([this.exportConstants.BLOB_HEADER + csvString], { type: this.exportConstants.BLOB_TYPE });
    this.exportUrl = this.sanitizer.bypassSecurityTrustUrl(window.URL.createObjectURL(blob));
    const title = this.titleService.text.getValue();
    this.exportFilename = `${title}.${this.exportConstants.FILE_EXTENSION_CSV}`;

    window.setTimeout(() => { // anchor requires time interval to refresh its state
      this.exportLink.nativeElement.click();
      csvString = '';
    });
  }

  getData() {
    if (!this.auctionClusterId || !this.catalogId) {
      return;
    }

    this.lastRecordedPage = this.currentPage;

    // Fetch lots without columns refresh
    this.fetchLots(false);
  }

  generateTable = (rows: any) => {
    this.gridColumns = [];
    let gridCurrentColums = this.dataGridInstance.getVisibleColumns();
    rows.columnTitles.forEach((row: any, i: number) => {
      let columnName = this.getTranslation(row.name);
      if (this.gridColumns.includes(columnName)) {
        let j = 0;
        do {
          columnName = this.getTranslation(row.name) + j;
          j++;
        } while (this.gridColumns.includes(columnName));
      }
      this.gridColumns.push(columnName);
      const isColumn = gridCurrentColums.find(c => c.dataField === columnName);

      if (!isColumn) {
        this.dataGridInstance.addColumn({
          dataField: columnName,
          caption: this.getTranslation(row.name),
          visibleIndex: i,
          allowEditing: false,
          dataType: this.getDataType(row.propertyTypeId, row.propertyTypeFormatId),
          encodeHtml: false,
          cellTemplate: this.assignDefaultCellTemplate(row.propertyTypeId, row.propertyTypeFormatId),
          editorOptions: {
            showClearButton: true
          }
        });
      }
    });

    if (this.prebidsEnabled) {
      const isColumnPrebidPrice = gridCurrentColums.find(c => c.dataField === this.getTranslation(this.translate.instant('SHARED.PREBID_PRICE')));
      this.gridColumns.push(this.getTranslation(this.translate.instant('SHARED.PREBID_PRICE')));
      if (!isColumnPrebidPrice) {
        gridCurrentColums = this.dataGridInstance.getVisibleColumns();
        this.dataGridInstance.addColumn({
          dataField: this.getTranslation(this.translate.instant('SHARED.PREBID_PRICE')),
          caption: this.getTranslation(this.translate.instant('SHARED.PREBID_PRICE')),
          visibleIndex: gridCurrentColums.length - 1,
          allowEditing: false
        });
      }

      const isColumnPrebidAmount = gridCurrentColums.find(c => c.dataField === this.getTranslation(this.translate.instant('SUPPLY_MONITOR.PREBID_AMOUNT')));
      this.gridColumns.push(this.getTranslation(this.translate.instant('SUPPLY_MONITOR.PREBID_AMOUNT')));
      if (!isColumnPrebidAmount) {
        gridCurrentColums = this.dataGridInstance.getVisibleColumns();
        this.dataGridInstance.addColumn({
          dataField: this.getTranslation(this.translate.instant('SUPPLY_MONITOR.PREBID_AMOUNT')),
          caption: this.getTranslation(this.translate.instant('SUPPLY_MONITOR.PREBID_AMOUNT')),
          visibleIndex: gridCurrentColums.length - 1,
          allowEditing: false
        });
      }

      const isColumnPrebidSubbuyer = gridCurrentColums.find(c => c.dataField === this.getTranslation(this.translate.instant('SHARED.PREBID_SUBBUYER')));
      this.gridColumns.push(this.getTranslation(this.translate.instant('SHARED.PREBID_SUBBUYER')));
      if (!isColumnPrebidSubbuyer) {
        gridCurrentColums = this.dataGridInstance.getVisibleColumns();
        this.dataGridInstance.addColumn({
          dataField: this.getTranslation(this.translate.instant('SHARED.PREBID_SUBBUYER')),
          caption: this.getTranslation(this.translate.instant('SHARED.PREBID_SUBBUYER')),
          visibleIndex: gridCurrentColums.length - 1,
          allowEditing: false
        });
      }
    }

    this.generateTableData();
  }

  generateTableData() {
    const gridItems = [];
    var imageColumns = this.columns.filter(tableColumn => tableColumn.propertyTypeId === ProductPropertyTypeEnum.URL && tableColumn.propertyTypeFormatId === UrlTypeEnum.IMAGE_URL);

    this.data.forEach((row, i) => {
      let gridItem = {};
      this.gridColumns.forEach((column, j) => {
        if (imageColumns.length !== 0 && imageColumns.filter(_ => _.name === column).length !== 0) {
          gridItem[column] = row['key' + j];
        } else {
          gridItem[column] = this.getTranslation(row['key' + j]);
        }
      });

      gridItem['__item__'] = row['__item__'];
      gridItems.push(gridItem);
    });

    const rows = this.dataGridInstance.getDataSource();
    if (rows === null) {
      if (gridItems.length > 0) {
        this.dataGridInstance.option('dataSource', gridItems);
      }
    } else {
      this.dataGridInstance.option('dataSource', gridItems);
    }
  }

  fetchLots(refreshColumns: boolean) {
    // Reset any existing filtering if column refresh is requested
    if (refreshColumns) {
      this.columns = [];
    }

    this.data = [];
    this.items = [];
    const dateFormatted = moment(this.date).local().format('YYYY-MM-DD');
    this.spinner.show();

    // Bulid filters for report
    this.filterReport();

    this.supplyMonitorService.getLots(this.id, this.auctionClusterId, this.catalogId, dateFormatted, this.genericFilters)
      .subscribe(result => {
        this.items = result.rows;
        if (refreshColumns) {
          // Parse columns only if explicitly requested
          this.parseColumns(result.columnTitles);
        }
        this.parseObject(result.rows);
        this.spinner.hide();
        this.lastRefresh = moment().locale(this.translateService.currentLang).format('DD.MM.YYYY. HH:mm');
        this.generateTable(result);
      },
        error => {
          this.errorService.show(error);
          this.spinner.hide();
        });
  }

  private parseObject(rows: Array<GenericLotRow>) {
    this.data = [];

    // Ignore parsing if there are no columns defined
    if (this.columns.length === 0) {
      return;
    }

    // Apply predib/marked filter for lots
    if (this.filterPrebids || this.filterMarked) {
      rows = rows.filter(_ => {
        return (this.filterPrebids && _.prebid) || (this.filterMarked && _.hasMark);
      });
    }

    rows.forEach(row => {
      const obj = new Object();
      this.columns.forEach((column, i) => {
        // Parse prebids
        if (row.prebid) {          
          // Add prebid price in values
          if (row.prebid.price !== 0) {
            row.values.push(row.prebid.price.toFixed(2).toString());
          }
          else {
            row.values.push(row.prebid.buyerActionOnLot.map(_ => _.price).filter(Boolean).join(", "));
          }

          // Add prebid amount in values
          if (row.prebid.price !== 0) {
            row.values.push(row.prebid.amountWanted.toFixed(2).toString());
          }
          else {
            row.values.push(row.prebid.buyerActionOnLot.map(_ => _.amountWanted).filter(Boolean).join(", "));
          }    

          // Find and add subbuyer in values
          let subbuyer = null;
          if (row.prebid.price !== 0) {
            if (row.prebid.subbuyerId && row.prebid.subbuyerId !== 0) {
              subbuyer = this.subbuyers.find(_ => _.buyerId === row.prebid.subbuyerId);
            }
            else {
              subbuyer = this.subbuyers.find(_ => _.buyerId === this.id);
            }
          }

          if (subbuyer) {
            row.values.push(`${subbuyer.subNumber} - ${subbuyer.name}`);
          } else {
            let subPrebidBuyers = "";
            row.prebid.buyerActionOnLot.forEach(sp => {
              if (sp.price !== 0) {
                let subPrebidSubbuyerId = sp.subbuyerId;
                if (subPrebidSubbuyerId === 0) subPrebidSubbuyerId = this.id;
                const subbuyerSubPrebid = this.subbuyers.find(_ => _.buyerId === subPrebidSubbuyerId);
                if (subPrebidBuyers.length > 0) {
                  subPrebidBuyers += ', ';
                }
                if (subbuyerSubPrebid)
                  subPrebidBuyers += `${subbuyerSubPrebid.subNumber} - ${subbuyerSubPrebid.name}`;
              }

            });
            // Add null if there is no subbuyer
            row.values.push(subPrebidBuyers);
          }      
        }
        if (column.propertyTypeId === ProductPropertyTypeEnum.DATE) {
          obj['key' + i] = this.addLineBreaks(row.values[i], column.propertyTypeId, column.propertyTypeFormatId);
        } else if (column.propertyTypeId === ProductPropertyTypeEnum.URL) {
          obj['key' + i] = row.values[i];
        }
        else if (column.propertyTypeId === ProductPropertyTypeEnum.TEXT
          || column.propertyTypeId === ProductPropertyTypeEnum.BOOLEAN
          || column.propertyTypeId === ProductPropertyTypeEnum.IMAGE
          || column.propertyTypeId === ProductPropertyTypeEnum.MASTER_DATA
          || isNaN(Number(row.values[i]))) {
          // Add line breaks for string value
          let objValue = this.getTranslation(row.values[i]);
          objValue = this.addLineBreaks(objValue, column.propertyTypeId);
          obj['key' + i] = objValue;
        } else {
          obj['key' + i] = row.values[i];//this.format(Number(row.values[i]), column.propertyTypeId);
        }
      });
      obj['__item__'] = row;

      this.data.push(obj);
    });

    // Reset default page size
    this.currentPage = this.lastRecordedPage;
    this.setPageSize(this.pageSizeValue);
  }

  private parseColumns(columns: Array<GenericLotColumn>) {
    const _columns = [];
    columns.forEach(column => {
      _columns.push({
        name: this.getTranslation(column.name),
        propertyTypeId: column.propertyTypeId,
        propertyTypeFormatId: column.propertyTypeFormatId
      });
    });

    // Additional columns for displaying prebid info
    _columns.push({
      name: this.getTranslation(this.translations.PREBID_PRICE),
      propertyTypeId: null
    });
    _columns.push({
      name: this.getTranslation(this.translations.PREBID_AMOUNT),
      propertyTypeId: null
    });
    _columns.push({
      name: this.getTranslation(this.translations.PREBID_SUBBUYER),
      propertyTypeId: null
    });


    const _objectKeys = [];
    _columns.forEach((column, i) => {
      _objectKeys.push('key' + i);
    });

    this.objectKeys = _objectKeys;
    this.columns = _columns;
    this.createDataSchema();
  }

  filterLots() {
    // Fetch data and force columns refresh
    this.fetchLots(true);
  }

  filterCatalogs() {
    if (!this.auctionClusterId) {
      this.catalogs = [];
      this.catalogId = null;
      return;
    }

    var catalogsRequest = new CatalogsRequest();
    catalogsRequest.salesDate = this.date;
    catalogsRequest.isTransaction = false;

    this.catalogService.getAllCatalogsWithSupplyOrTransactions(catalogsRequest).subscribe(result => {
      this.catalogs = result;
      if (this.catalogs.length !== 0) {
        this.catalogId = this.catalogs[0].catalogId;
      }
    });
  }

  onCatalogChange() {
    var catalog = this.catalogs.find(_ => _.catalogId == this.catalogId);
    if (catalog) {
      this.prebidsEnabled = catalog.prebid != 0;

      this.productService.getPrebidOnProductProperties(this.auctionClusterId, catalog.productId).subscribe(prebidOnProductProperties => {
        this.prebidOnProductEnabled = prebidOnProductProperties.length > 0;
      });
    }
  }

  openPrebids() {
    const catalog = this.catalogs.find(c => c.catalogId === this.catalogId);
    this.prebids.open(this.auctionClusterId, catalog.productId, this.secondRestrictionMasterDataId, this.buyerSecondRestrictionMasterDataRowIds);
  }

  getSubbuyers() {
    if (!this.auctionClusterId) {
      this.subbuyers = [];
      return;
    }

    this.supplyMonitorService.getSubbuyers(this.id, this.auctionClusterId).subscribe(result => {
      this.subbuyers = result;
    });
  }

  edit(lotId: number, event: Event) {
    this.detailsComponent.modalTitle = this.prebidsEnabled ? this.translations.EDIT_PREBID : this.translations.SHOW_DETAILS;
    const catalog = this.catalogs.find(c => c.catalogId === this.catalogId);
    var showMaxAmountPrebidField = catalog.prebidMinMaxAmounts !== PrebidMinMaxAmountsEnum.PREBIDS_ARE_IGNORED; 

    this.detailsComponent.open(this.items, lotId, this.auctionClusterId, this.catalogId, this.subbuyers,
      this.date, catalog.allowPartialSaleOfSingleLotWithMultipleSaleUnits, catalog.amountIsBoxContentMultitude,
      catalog.allowMultiplePrebidsPerBuyerOnSameLot, showMaxAmountPrebidField, this.prebidsEnabled && !this.isBuyerViewer);
  }

  editClick(e: any) {
    var blockedBySecondRestriction = e.data.__item__.blockedBySecondRestriction;
    if (blockedBySecondRestriction)
      return;

    var lotId = e.data.__item__.id;

    this.detailsComponent.modalTitle = this.prebidsEnabled ? this.translations.EDIT_PREBID : this.translations.SHOW_DETAILS;
    const catalog = this.catalogs.find(c => c.catalogId === this.catalogId);

    var showMaxAmountPrebidField = catalog.prebidMinMaxAmounts !== PrebidMinMaxAmountsEnum.PREBIDS_ARE_IGNORED; 
    if (!this.popUpShowed && !this.popUpOpened) {
      this.detailsComponent.open(this.items, lotId, this.auctionClusterId, this.catalogId, this.subbuyers,
        this.date, catalog.allowPartialSaleOfSingleLotWithMultipleSaleUnits, catalog.amountIsBoxContentMultitude,
        catalog.allowMultiplePrebidsPerBuyerOnSameLot, showMaxAmountPrebidField, this.prebidsEnabled && !this.isBuyerViewer);
    }
  }

  editBackground(e: any) {
    if (e.rowType == 'data') {
      if (e.data.__item__.prebid) {
        e.rowElement.style.backgroundColor = '#A3EDB2';
      }
      else if (e.data.__item__.hasMark) {
        e.rowElement.style.backgroundColor = '#A3C4ED';
      }
    }
  }

  editCellBackground(e: any) {
    if (e.rowType == 'data') {
      if (e.data.__item__.prebid) {
        e.cellElement.style.backgroundColor = '#A3EDB2';
      }
      else if (e.data.__item__.hasMark) {
        e.cellElement.style.backgroundColor = '#A3C4ED';
      }
    }
  }

  markLot(lot: GenericLotRow, isMark: boolean, event: Event) {
    event.stopPropagation();
    const prebid = new BuyerActionOnLotGroup();
    prebid.buyerId = this.id;
    prebid.catalogId = this.catalogId;
    prebid.groupedIds = lot.groupedIds;

    if (isMark) {
      this.supplyMonitorService.saveMark(prebid, this.auctionClusterId).subscribe(result => {
        this.getData();
      }, err => {
        this.errorService.show(err);
      });
    } else {
      this.supplyMonitorService.deleteMark(prebid, this.auctionClusterId).subscribe(result => {
        this.getData();
      }, err => {
        this.errorService.show(err);
      });
    }
  }

  dateChanged(dateTime: Moment) {
    this.date = moment(dateTime).toDate();
    this.filterCatalogs();
  }

  delete(itemId: number, event: Event) {
    event.stopPropagation();
    this.itemIdToDelete = itemId;
    this.deleteSelected();
  }

  deleteSelected() {
    const lot = this.items.find(l => l.id === this.itemIdToDelete);
    if (lot && lot.prebid) {
      this.supplyMonitorService.deletePrebid(lot.prebid, this.auctionClusterId).subscribe(result => {
        this.getData();
      });
    }
  }

  getReportTemplates() {
    this.reportService.getReportsBySystemReportDesignType(this.id, SystemReportDesignTypes.BuyerSupplyMonitorFilter).subscribe(result => {
      this.allReports = result;
      if (this.allReports.length !== 0) {
        let report = this.allReports.find(_ => _.systemDefault);
        if (report) {
          this.applyReportFilters(report);
        }
        else {
          this.getDefaultReport();
        }
      }
      else {
        this.getDefaultReport();
      }
    }, error => {
      this.errorService.show(error);
      this.getDefaultReport();
    });
  }

  getDefaultReport() {
    this.reportService.getReportBySystemReportDesignType(this.id, SystemReportDesignTypes.BuyerSupplyMonitorFilter).subscribe(result => {
      this.applyReportFilters(result);
    });
  }

  applyReportFilters(report: Report) {
    this.filters = [];

    if (!report || report.reportId == 0)
      return;

    this.report = report;
    this.reportId = report.reportId;
    this.reportName = this.report.description;
    this.productId = this.report.reportDesign.productId;

    forkJoin(
      this.productService.getProductProperties(this.productId),
      this.productService.getReportProperties(this.productId, this.report.reportDesignId)
    ).subscribe((result: Array<any>) => {
      this.productProperties = result[0];
      this.reportProperties = result[1];
      this.getMasterDataListsData();
    },
      error => {
        this.errorService.show(error);
      });
  }

  getMasterDataListsData() {
    const masterDataLists: Array<MasterData> = new Array<MasterData>();

    const masterDataListIds: Array<number> = [];
    const reportProperties = this.reportProperties.filter(x => x.propertyTypeId = this.reportId);

    reportProperties.forEach(reportProperty => {
      const productProperty = this.productProperties.filter(x => x.productPropertyId === reportProperty.productPropertyId)[0];
      const productPropertyTypeId = productProperty.propertyTypeId;
      const productPropertyMasterDataListId = productProperty.masterDataListId;
      // We show only date and master data property types.
      if (productPropertyTypeId === ProductPropertyTypeEnum.DATE || productPropertyTypeId === ProductPropertyTypeEnum.MASTER_DATA) {
        if (productPropertyMasterDataListId !== null) {
          if (masterDataListIds.indexOf(productPropertyMasterDataListId) === -1) {
            masterDataListIds.push(productPropertyMasterDataListId);
          }
        }
      }
    });

    const observables = [];

    masterDataListIds.forEach(id => {
      observables.push(this.masterDataService.getMasterDataForReports(this.auctionClusterId, id, true).pipe(map((result: MasterData) => result)));
    });

    if (observables.length > 0) {
      forkJoin(
        observables
      ).subscribe((lists: Array<MasterData>) => {
        lists.forEach((result: MasterData) => {
          masterDataLists.push(result);
        });
        this.initReportData(this.auctionClusterId, lists);
      });
    } else {
      this.initReportData(this.auctionClusterId, new Array<MasterData>());
    }
  }

  initReportData(auctionClusterId: number, masterDataLists: Array<MasterData>) {
    this.masterDataDropdowns.length = 0;
    this.reportProperties.filter(x => x.reportDesignId === this.report.reportDesignId)
      .sort((s, z) => s.orderNumber.toString().localeCompare(z.orderNumber.toString()))
      .forEach(reportProperty => {
        const productProperty = this.productProperties.filter(x => x.productPropertyId === reportProperty.productPropertyId)[0];
        const productPropertyId = productProperty.productPropertyId;
        const productPropertyTypeId = productProperty.propertyTypeId;
        const productPropertyMasterDataListId = productProperty.masterDataListId;
        const reportPropertyMasterDataListFieldId = reportProperty.masterDataListFieldId;

        const filter = {
          name: reportProperty.name !== null ? reportProperty.name : productProperty.name,
          sysName: productProperty.sysName,
          propertyTypeId: productPropertyTypeId,
          productPropertyId,
          masterDataListId: productPropertyMasterDataListId,
          masterDataListFieldId: reportPropertyMasterDataListFieldId,
          options: []
        };

        const masterData = masterDataLists.filter(x => x.masterDataListId === productPropertyMasterDataListId
          && x.auctionClusterId === auctionClusterId)[0];

        // We show only date and master data property types.
        if (productPropertyTypeId === ProductPropertyTypeEnum.DATE || productPropertyTypeId === ProductPropertyTypeEnum.MASTER_DATA) {
          if (productPropertyMasterDataListId !== null) {
            masterData.rows.forEach(row => {
              let rowValueText = '';
              row.values.filter(x => x.masterDataListFieldId === reportPropertyMasterDataListFieldId).forEach(rowValue => {
                if (rowValue.stringValue !== undefined) {
                  rowValueText += rowValue.stringValue;
                }
                if (rowValue.intValue !== undefined) {
                  rowValueText += rowValue.intValue;
                }
              });

              filter.options.push({
                'label': rowValueText,
                'value': row.masterDataListRowId
              });
            });
          }

          this.masterDataDropdowns.push({ productPropertyId: reportProperty.productPropertyId, values: filter.options });

          this.filters.push(filter);

          this.filterBindings[filter.productPropertyId] = this.filterBindings[filter.productPropertyId] !== undefined
            ? this.filterBindings[filter.productPropertyId]
            : null;

          if (productPropertyTypeId === ProductPropertyTypeEnum.MASTER_DATA && reportProperty.defaultMasterDataListRowId !== null) {
            this.filterBindings[filter.productPropertyId] = reportProperty.defaultMasterDataListRowId;
          }

          this.dateFiltersCascadingDefinitions[filter.productPropertyId] =
            this.dateFiltersCascadingDefinitions[filter.productPropertyId] !== null ?
              this.dateFiltersCascadingDefinitions[filter.productPropertyId] : null;

          if (productPropertyTypeId === ProductPropertyTypeEnum.DATE) {
            switch (reportProperty.dateType) {
              case DateRanges.RANGE: {
                this.dateFiltersCascadingDefinitions[filter.productPropertyId] = DateRanges.RANGE.toString();
                this.filterBindings[filter.productPropertyId] = [
                  moment.utc(reportProperty.defaultFromDate),
                  moment.utc(reportProperty.defaultTillDate)
                ];
                break;
              }
              case DateRanges.TODAY: {
                this.dateFiltersCascadingDefinitions[filter.productPropertyId] = DateRanges.TODAY.toString();
                this.filterBindings[filter.productPropertyId] = moment.utc().hours(NOON).format();
                break;
              }
              case DateRanges.YESTERDAY: {
                this.dateFiltersCascadingDefinitions[filter.productPropertyId] = DateRanges.YESTERDAY.toString();
                this.filterBindings[filter.productPropertyId] = moment.utc().subtract(1, 'days').hours(NOON).format();
                break;
              }
              case DateRanges.LAST_WEEK: {
                const dateFrom = moment.utc().subtract(1, 'weeks').hours(NOON);
                const dateTo = moment.utc().hours(NOON);
                this.dateFiltersCascadingDefinitions[filter.productPropertyId] = DateRanges.LAST_WEEK.toString();
                this.filterBindings[filter.productPropertyId] = [dateFrom, dateTo];
                break;
              }
              default:
                break;
            }
          }
        }
      });

    this.masterDataDropdownsFiltered = JSON.parse(JSON.stringify(this.masterDataDropdowns));
    this.dropdownsData = JSON.parse(JSON.stringify(this.masterDataDropdowns));

    this.parseReportFilterData();
    this.setComboBoxes();
  }

  parseReportFilterData() {
    if (!this.report.data) {
      return;
    }

    const filterData = JSON.parse(this.report.data);

    filterData.forEach(filter => {
      if (filter.productPropertyTypeId === ProductPropertyTypeEnum.MASTER_DATA) {
        this.filterBindings[filter.productPropertyId] = filter.value;
      } else if (filter.productPropertyTypeId === ProductPropertyTypeEnum.DATE) {
        if (filter.dateRangeType !== null) {
          this.dateFiltersCascadingDefinitions[filter.productPropertyId] = filter.dateRangeType.toString();
          if (filter.dateRangeType === DateRanges.RANGE) {
            this.filterBindings[filter.productPropertyId] = [
              moment.utc(filter.value.split('|')[0]),
              moment.utc(filter.value.split('|')[1]),
            ];
          }
        } else {
          this.filterBindings[filter.productPropertyId] = moment.utc(filter.value);
        }
      }
    });
  }

  getFilterForServer() {
    const filterForServer: Array<ReportFilterModel> = [];

    for (const prop of Object.keys(this.filterBindings)) {
      let filterValue = null;
      let filterType = null;

      const filter = this.filters.filter(x => x.productPropertyId === +prop)[0];

      filterType = filter.propertyTypeId;

      let dateFilter = null;

      if (filter.propertyTypeId === ProductPropertyTypeEnum.DATE) {
        dateFilter = this.dateFiltersCascadingDefinitions[prop] ? +this.dateFiltersCascadingDefinitions[prop] : null;
        if (dateFilter === DateRanges.TODAY) {
          filterValue = moment.utc().hours(NOON).format();
        }
        if (dateFilter === DateRanges.YESTERDAY) {
          filterValue = moment.utc().subtract(1, 'days').hours(NOON).format();
        }
        if (dateFilter === DateRanges.LAST_WEEK) {
          const dateFrom = moment.utc().subtract(1, 'weeks').hours(NOON);
          const dateTo = moment.utc().hours(NOON);
          filterValue = dateFrom.format() + '|' + dateTo.format();
        }
        if (dateFilter === DateRanges.RANGE) {
          const dateFrom = this.filterBindings[prop][0];
          const dateTo = this.filterBindings[prop][1];
          filterValue = moment(dateFrom).hours(NOON).format() + '|' + moment(dateTo).hours(NOON).format();
        }
      } else {
        filterValue = this.filterBindings[prop];
      }

      filterForServer.push({
        productPropertyTypeId: +filterType,
        productPropertyId: +prop,
        dateRangeType: dateFilter,
        key: filter.sysName,
        value: filterValue
      });
    }

    return filterForServer;
  }

  filterReport() {
    const reportId = this.report.reportId;
    const filterForServer = this.getFilterForServer();

    const reportRequestModel = {
      LanguageCode: this.translateService.currentLang,
      TimeZoneOffset: new Date().getTimezoneOffset(),
      UserId: this.authenticationService.currentUserId,
      Filters: filterForServer
    };
    this.genericFilters = btoa(JSON.stringify(reportRequestModel));
  }

  openSaveAsNewUserReportTemplateDialog() {
    // this.detailsForm.reset();
    this.isSaveProfileDialogOpened = true;
  }

  closeSaveAsNewUserReportTemplateDialog() {
    this.isSaveProfileDialogOpened = false;
  }

  save() {
    this.report.data = JSON.stringify(this.getFilterForServer());
    this.spinner.show();
    this.reportService.edit(this.id, this.report).subscribe(report => {
      this.spinner.hide();
    },
      error => {
        this.spinner.hide();
        this.isSaveProfileDialogOpened = false;
        this.errorService.show(error);
      });
  }

  saveNewReport() {
    const newReport: Report = JSON.parse(JSON.stringify(this.report));
    newReport.data = JSON.stringify(this.getFilterForServer());
    newReport.systemDefault = false;
    newReport.description = this.newReportName;

    this.spinner.show();
    this.reportService.save(this.id, newReport).subscribe(report => {
      this.spinner.hide();
      this.isSaveProfileDialogOpened = false;
      this.getReportTemplates();
    },
      error => {
        this.spinner.hide();
        this.isSaveProfileDialogOpened = false;
        this.errorService.show(error);
      });
  }

  setLotPropertyValue(productPropertyId: number, value: any) {
    let isSame = false;
    if (this.filterBindings[productPropertyId] === value) {
      isSame = true;
    }
    this.filterBindings[productPropertyId] = value;

    return isSame;
  }

  clearLotPropertyValue(productPropertyId: number, e: any) {
    if (e.value === null) {
      this.filterBindings[productPropertyId] = null;
    }
  }

  setComboBoxes() {
    this.comboBoxes.length = 0;
    this.filters.forEach((f, i) => {
      this.comboBoxes[i] = this.getDropdownValues(f);
    });
  }

  getDropdownValues(filter: any) {
    const masterDataDropdown = this.dropdownsData.find(dd => dd.productPropertyId === filter.productPropertyId);
    if (masterDataDropdown) {
      const values = masterDataDropdown.values;

      const currentValue = values.find(v => v.value === this.filterBindings[filter.productPropertyId]);
      if (!currentValue) {
        const md = this.masterDataDropdowns.find(dd => dd.productPropertyId === filter.productPropertyId);
        if (md) {
          const row = md.values.find(v => v.value === filter.masterDataListFieldId);
          if (row) {
            values.push(row);
          }
        }
      }
      values.sort((a, b) => {
        if (a.label < b.label) {
          return -1;
        }
        if (a.label > b.label) {
          return 1;
        }

        return 0;
      });

      if (values.length === 1) {
        const isSame = this.setLotPropertyValue(filter.productPropertyId, values[0].value);
        if (!isSame) {
          this.onPropertyChanged(filter);
        }
      }

      return values;
    }
  }

  getPropIdsForMasterDataFields(lookupTableFields: Array<LookupTableField>) {
    const ids = [];
    lookupTableFields.forEach(ltf => {
      if (ltf.isResult) {
        this.productProperties.forEach(pp => {
          if (pp.masterDataListId === ltf.masterDataListId) {
            ids.push(pp.productPropertyId);
          }
        });
      }
    });

    return ids;
  }

  getLookupDependentPropIds(productPropertyId: number) {
    let dependentPropIds = [];
    const productProperty = this.productProperties.find(f => f.productPropertyId === productPropertyId);
    if (!productProperty) {
      return dependentPropIds;
    }
    const productPropertyMasterData = productProperty.masterDataListId;
    if (!productPropertyMasterData) {
      return dependentPropIds;
    }
    for (let i = 0; i < this.lookupTables.length; i += 1) {
      if (this.lookupTables[i].lookupTableType === 1) {
        continue;
      }
      for (let j = 0; j < this.lookupTables[i].lookupTableFields.length; j += 1) {
        if (!this.lookupTables[i].lookupTableFields[j].isResult
          && this.lookupTables[i].lookupTableFields[j].masterDataListId === productPropertyMasterData) {
          const propIds = this.getPropIdsForMasterDataFields(this.lookupTables[i].lookupTableFields);
          dependentPropIds = [...dependentPropIds, ...propIds];
        }
      }
    }

    return dependentPropIds;
  }

  isProductWithLookupTable(productPropertyId: number) {
    for (let i = 0; i < this.productProperties.length; i += 1) {
      if (this.productProperties[i].productPropertyId === productPropertyId
        && this.productProperties[i].lookupTableId) {
        return this.productProperties[i].lookupTableId;
      }
    }

    return -1;
  }

  onPropertyChanged(filter: any) {
    const applyLookup = (lookup, i) => {
      if (lookup) {
        if (lookup.lookupTableType === 0 && lookup.lookupTableRows.length > 0) {
          const newValues = [];
          lookup.lookupTableRows.forEach(row => {
            row.lookupTableValues.forEach(value => {
              this.masterDataDropdowns[i].values.forEach(mdValue => {
                if (mdValue.value === value.masterDataListRowId && newValues.indexOf(mdValue) === -1) {
                  newValues.push(mdValue);
                }
              });
            });
          });
          if (newValues.length > 0) {
            this.dropdownsData.push({ productPropertyId: this.masterDataDropdowns[i].productPropertyId, values: newValues });
            this.masterDataDropdownsFiltered[i] = {
              productPropertyId: this.masterDataDropdowns[i].productPropertyId, values: newValues, filtered: true
            };
          }
          const defaultRow = lookup.lookupTableRows.find(r => r.isDefault);

          if (defaultRow) {
            defaultRow.lookupTableValues.forEach(ltv => {
              if (newValues.find(nv => nv.value === ltv.masterDataListRowId)) {
                this.setLotPropertyValue(this.masterDataDropdowns[i].productPropertyId, ltv.masterDataListRowId);
              }
            })
          }
        } else {
          if (this.masterDataDropdownsFiltered[i].filtered === true && this.filterBindings[filter.productPropertyId]) {
            this.dropdownsData.push(this.masterDataDropdownsFiltered[i]);
          } else {
            this.dropdownsData.push(this.masterDataDropdowns[i]);
          }
        }
      } else {
        if (this.masterDataDropdownsFiltered[i].filtered === true && this.filterBindings[filter.productPropertyId]) {
          this.dropdownsData.push(this.masterDataDropdownsFiltered[i]);
        } else {
          this.dropdownsData.push(this.masterDataDropdowns[i]);
        }
      }
      this.setComboBoxes();
    };

    const productPropertyIds = this.getLookupDependentPropIds(filter.productPropertyId);

    if (productPropertyIds.length === 0) {
      return;
    }

    // clear values first
    this.dropdownsData.length = 0;
    for (let i = 0; i < this.masterDataDropdowns.length; i += 1) {
      let checkThisProp = this.masterDataDropdowns[i].productPropertyId !== filter.productPropertyId;
      if (productPropertyIds.length > 0 && productPropertyIds.indexOf(this.masterDataDropdowns[i].productPropertyId) === -1) {
        checkThisProp = false;
      }
      const lookupTableId = this.isProductWithLookupTable(this.masterDataDropdowns[i].productPropertyId);
      if (checkThisProp && lookupTableId !== -1) {
        const lot = this.createLotFromBindings();
        this.lookupTableService.getLookupTableForProductPropertyForReports(null, this.masterDataDropdowns[i].productPropertyId, lot)
          .subscribe(lookup => {
            applyLookup(lookup, i);
          }, error => {
            if (this.masterDataDropdownsFiltered[i].filtered === true && this.filterBindings[filter.productPropertyId]) {
              this.dropdownsData.push(this.masterDataDropdownsFiltered[i]);
            } else {
              this.dropdownsData.push(this.masterDataDropdowns[i]);
            }
            this.setComboBoxes();
          });
      } else {
        if (this.masterDataDropdownsFiltered[i].filtered === true && this.filterBindings[filter.productPropertyId]) {
          this.dropdownsData.push(this.masterDataDropdownsFiltered[i]);
        } else {
          this.dropdownsData.push(this.masterDataDropdowns[i]);
        }
        if (productPropertyIds.length > 0) {
          this.setComboBoxes();
        }
      }
    }
  }

  createLotFromBindings() {
    const lot = new Lot();

    this.filters.forEach(f => {
      if (f.propertyTypeId === ProductPropertyTypeEnum.MASTER_DATA) {
        const lotProp = new LotProperty();
        lotProp.productPropertyId = f.productPropertyId;
        lotProp.masterDataListRowId = this.filterBindings[f.productPropertyId];
        lot.lotProperties.push(lotProp);
      }
    });

    return lot;
  }

  showLoadReportProfile() {
    this.isLoadProfileDialogOpened = true;
  }

  closeLoadReportProfile() {
    this.isLoadProfileDialogOpened = false;
  }

  reportTemplateChange() {
    this.isLoadProfileDialogOpened = false;

    let report = this.allReports.find(_ => _.reportId === this.reportId);
    if (report) {
      this.applyReportFilters(report);
    }
  }

  initColumn = (e: any) => {
    this.dataGridInstance = e.component;
  }

  getFilterSelectBoxValue = (e: any) => {
    console.log(e);
  }

  translateSelectBoxes = (item: any) => {
    if (item) {
      const label = this.getTranslation(item.label);
      return label;
    }
  }

  prepareData(index: number) {
    return this.comboBoxes[index];
  }

  prepareValue(index: number) {
    return this.filterBindings[index];
  }

  handleDate1ValueChange(index: number, e: any) {
    if (e.value != null) {
      this.filterBindings[index] = [];
      this.filterBindings[index].push(moment(e.value));
    }
  }

  handleDate2ValueChange(index: number, e: any) {
    if (e.value != null) {
      this.filterBindings[index].push(moment(e.value));
    }
  }

  dateValue1(index: number) {
    if (Array.isArray(this.filterBindings[index]) && this.filterBindings[index].length > 0) {
      return moment(this.filterBindings[index][0]).toDate();
    } else {
      return null;
    }
  }

  dateValue2(index: number) {
    if (Array.isArray(this.filterBindings[index]) && this.filterBindings[index].length > 1) {
      return moment(this.filterBindings[index][1]).toDate();
    } else {
      return null;
    }
  }

  getMin(index: number) {
    if (Array.isArray(this.filterBindings[index]) && this.filterBindings[index].length > 0) {
      return moment(this.filterBindings[index][0]).toDate();
    }
  }

  isDisabled(index: number) {
    if (Array.isArray(this.filterBindings[index])) {
      if (this.filterBindings[index].length === 0) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

  clearDatePeriod(index: number) {
    if (this.dateFiltersCascadingDefinitions[index] !== 3) {
      this.filterBindings[index] = [];
    }
  }

  setValue(index: number, e: any) {
    this.dateFiltersCascadingDefinitions[index] = e.value;
  }

  getDateValue(index: number) {
    return Number(this.dateFiltersCascadingDefinitions[index]);
  }

  isDisabledButton() {
    if (this.newReportName !== '') {
      return false;
    } else {
      return true;
    }
  }

  isDisabledLoadButton() {
    if (this.reportId !== null) {
      return false;
    } else {
      return true;
    }
  }

  doesImageCalculationExist(data: any) {
    if (data && this.isJSONString(data) && JSON.parse(data).find(el => el.field_0 !== '')) {
      return true;
    }
    return false;
  }

  getFirstDefinedImage(data: any) {
    let firstImageUrl = JSON.parse(data).find(el => el.field_0 !== '').field_0;
    return firstImageUrl + '-thumbnail';
  }

  showImageCarrousel(data: any) {
    this.carrouselData = JSON.parse(data).filter(el => el.field_0 !== '');
    this.spinner.show();
    this.popUpOpened = true;

    if (this.carrouselLoaded) {
      this.spinner.hide();
      this.popUpShowed = true;
    }
  }

  assignDefaultCellTemplate(propertyTypeId: ProductPropertyTypeEnum, propertyTypeFormatId?: UrlTypeEnum) {
    if (propertyTypeId === ProductPropertyTypeEnum.URL && propertyTypeFormatId === UrlTypeEnum.IMAGE_URL) {
      return 'imageCellTemplate';
    } else if (propertyTypeId === ProductPropertyTypeEnum.TEXT) {
      return 'textCellTemplate';
    } else {
      return 'cellTemplate';
    }
  }

  openPopUp() {
    this.spinner.hide();
    this.popUpShowed = true;
    this.carrouselLoaded = true;
  }

  closePopUp() {
    this.popUpOpened = false;
    this.popUpShowed = false;
    this.carrouselLoaded = false;
  }

  // fixes some severe layout issues when printing datagrid, hide the buttons column while we're at it
  printGrid() {
    this.supplyGrid.rowAlternationEnabled = false;
    this.supplyGrid.columnAutoWidth = false;
    this.supplyGrid.customizeColumns = (columns) => {
      var buttonColumn = columns.find(c => c.name == 'buttons');
      if(buttonColumn != null)
        buttonColumn.visible = false;
    };
    this.supplyGrid.applyOptions();
    setTimeout(() => {
      window.print();
      this.supplyGrid.rowAlternationEnabled = true;
      this.supplyGrid.columnAutoWidth = true;
      this.supplyGrid.customizeColumns = (columns) => {
        var buttonColumn = columns.find(c => c.name == 'buttons');
        if (buttonColumn != null)
          buttonColumn.visible = true;
      };
      this.supplyGrid.applyOptions();
    }, 300);
  }

  add() { }
}
