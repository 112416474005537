import { Component, EventEmitter, Injector, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

// components
import { ItemDetailsComponent } from '../../../shared/components/item-details/item-details.component';

// models
import { ProductFunctionKeysExpression, ProductProperty, SystemProductPropertyTypesEnum } from '../../../shared/models/product';
import { ProductPropertyTypeEnum } from '../../../shared/enum/productPropertyTypeEnum';
// services
import { ProductService } from '../../shared/services/product.service';

@Component({
  selector: 'product-functionkeys-expression-component',
  templateUrl: './product-functionkeys-expression.component.html',
  styleUrls: ['./product-functionkeys-expression.component.scss']
})
export class ProductFunctionkeysExpressionComponent extends ItemDetailsComponent<ProductFunctionKeysExpression> implements OnInit, OnDestroy {

  @Input('productProperties')
  set productProperty(value: Array<ProductProperty>) {
    this.productProperties = value.filter(_ => _.systemProductPropertyType === SystemProductPropertyTypesEnum.MinAmount
      || _.systemProductPropertyType === SystemProductPropertyTypesEnum.MaxAmount
      || _.systemProductPropertyType === SystemProductPropertyTypesEnum.MinPriceStop1
      || _.systemProductPropertyType === SystemProductPropertyTypesEnum.MinPriceStop2
      || _.systemProductPropertyType === SystemProductPropertyTypesEnum.MinPriceStop3
      || _.systemProductPropertyType === SystemProductPropertyTypesEnum.MinPriceStop4
      || _.systemProductPropertyType === SystemProductPropertyTypesEnum.Multitude
      || _.propertyTypeId === ProductPropertyTypeEnum.BOOLEAN
    );
  }

  @Output() onDetailsClosed = new EventEmitter<ProductFunctionKeysExpression>();

  auctionClusterId: number;
  productId: number;
  productProperties: Array<ProductProperty> = [];

  rtlEnabled = localStorage.getItem('last-selected-language-direction') ? JSON.parse(localStorage.getItem('last-selected-language-direction')) : false;
  private _subscription: Subscription;

  constructor(
    protected injector: Injector,
    private route: ActivatedRoute,
    private productService: ProductService,
  ) {
    super(injector);
    this._subscription = this.language.direction.subscribe(dir => {
      this.rtlEnabled = dir;
    });
  }

  ngOnInit() {
    this.auctionClusterId = +this.route.snapshot.params['id'];
    this.productId = +this.route.snapshot.params['productId'];

    this.model = new ProductFunctionKeysExpression();
  }

  ngOnDestroy() {
    this._subscription.unsubscribe();
  }

  open(items: Array<ProductFunctionKeysExpression>, expressionId: number) {
    this.model = items.find((item) => item.productFunctionKeysExpressionId === expressionId) || new ProductFunctionKeysExpression();
    this.isEditMode = expressionId != null;
    this.isOpened = true;
  }

  save() {
    this.model.productId = this.productId;

    this.spinner.show();

    this.productService.checkExpression(this.auctionClusterId, this.productId, this.model)
      .subscribe((res: any) => {
        this.spinner.hide();
        this.onDetailsClosed.emit(this.model);
        this.close(true);
        this.errorMessage = null;
      },
        error => {
          this.errorService.show(error);
          this.spinner.hide();
        });
  }

  onFieldDataChanged(e: any) {
    if (e.component._isReady && e.component.NAME !== 'dxPopup') {
      const result = e.component.validate();
      if (result.brokenRules.length >= 1) {
        document.getElementsByName('btnProductFunctionkeysExpressionSubmit')[0].setAttribute('disabled', 'disabled');
      } else {
        document.getElementsByName('btnProductFunctionkeysExpressionSubmit')[0].removeAttribute('disabled');
      }
    } else {
      if (this.isEditMode) {
        document.getElementsByName('btnProductFunctionkeysExpressionSubmit')[0].removeAttribute('disabled');
      }
    }
  }
}
