import { Component, OnInit, Injector, OnDestroy, HostListener, Output, EventEmitter} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import * as _moment from 'moment';

// components
import { ItemDetailsComponent } from '../../../../shared/components/item-details/item-details.component';

// models
import { GenericLotRow, GenericLotColumn, BuyerActionOnLotGroup, BuyerActionOnLot, GenericLots } from '../../../../shared/models/generic-lot';
import { ProductPropertyTypeEnum } from '../../../../shared/enum/productPropertyTypeEnum';
import { UrlTypeEnum } from '../../../../shared/enum/urlTypeEnum';

import { BuyerLevelSubbuyer } from '../../../shared/models/buyer-level-subbuyer';

// services
import { TranslateService } from '@ngx-translate/core';
import { SupplyMonitorService } from '../../../shared/services/supply-monitor.service';

const moment = (_moment as any).default ? (_moment as any).default : _moment;

@Component({
  selector: 'prebid-on-lot-component',
  templateUrl: 'prebid-on-lot.component.html',
  styleUrls: ['./prebid-on-lot.component.scss']
})
export class PrebidOnLotComponent extends ItemDetailsComponent<GenericLotRow> implements OnInit, OnDestroy {

  @Output() saveErrorEvent = new EventEmitter();
  buyerId: number;
  auctionClusterId: number;
  subbuyers: Array<BuyerLevelSubbuyer>;
  lotId: number;
  catalogId: number;
  prebid: BuyerActionOnLotGroup = new BuyerActionOnLotGroup();
  data: Array<any> = new Array<any>();
  objectKeys: Array<string> = new Array<string>();
  items = [];
  columns: Array<any> = [];
  prebidForAll: boolean;
  allowPartialLotPrebid: boolean;
  amountIsBoxContentMultitude: boolean;
  allowMultiplePrebidsPerBuyerOnSameLot: boolean;
  rtlEnabled = localStorage.getItem('last-selected-language-direction') ? JSON.parse(localStorage.getItem('last-selected-language-direction')) : false;
  private _subscription: Subscription;
  dataGridInstance: any;
  prebidsEnabled: boolean;
  // currentPage = 1;
  // lastRecordedPage = 1;

  isOpened: boolean = false;
  popUpShowed: boolean = false;
  popUpOpened: boolean = false;
  imageLoaded: boolean = false;
  largeImageSrc: string = '';
  imageCaption: string = '';
  imagePopUpShown: boolean = false;
  showMaxAmountPrebidField: boolean = false;

  constructor(
    protected injector: Injector,
    private dataService: SupplyMonitorService,
    private route: ActivatedRoute,
    private translateService: TranslateService
  ) {
    super(injector);
    this._subscription = this.language.direction.subscribe(dir => {
      this.rtlEnabled = dir;
    });
  }

  ngOnInit() {
    this.model = new GenericLotRow();
    this.buyerId = +this.route.snapshot.params['id'];
    this.setTranslations('BUYER');
  }

  ngOnDestroy() {
    this._subscription.unsubscribe();
  }  

  onKeyDown(event: any) {
    if (event.event.originalEvent.key === 'Enter') {
      // Create a new KeyboardEvent simulating a Tab key press
      const tabEvent = new KeyboardEvent('keydown', {
        key: 'Tab',
        code: 'Tab',
        keyCode: 9, 
        which: 9,
        shiftKey: event.shiftKey,
        bubbles: true,
        cancelable: true
      });
      event.event.originalEvent.target.dispatchEvent(tabEvent);
    }
  }

  open(lots: Array<GenericLotRow>, lotId: number, auctionClusterId: number, catalogId: number,
    subbuyers: Array<BuyerLevelSubbuyer>, date: Date, allowPartialLotPrebid: boolean, amountIsBoxContentMultitude: boolean, allowMultiplePrebidsPerBuyerOnSameLot: boolean,
    showMaxAmountPrebidField: boolean, prebidsEnabled: boolean = true) {

    this.allItems = lots;
    this.auctionClusterId = auctionClusterId;
    this.catalogId = catalogId;
    this.subbuyers = subbuyers;
    this.modalTitle = this.getTranslation(this.translations.PREBID);
    this.subbuyers.sort((a, b) => {
      return a.subNumber - b.subNumber;
    });
    this.allowPartialLotPrebid = allowPartialLotPrebid;
    this.amountIsBoxContentMultitude = amountIsBoxContentMultitude;
    this.allowMultiplePrebidsPerBuyerOnSameLot = allowMultiplePrebidsPerBuyerOnSameLot;
    this.prebidsEnabled = prebidsEnabled;
    this.showMaxAmountPrebidField = showMaxAmountPrebidField;

    this.prebid = null;
    this.model = this.allItems.find(l => l.id === lotId);
    if (this.model.prebid) {
      // Clone object to break reference since prebid object
      // is not being fetched from the API
      this.prebid = { ...this.model.prebid };
    }

    this.dataService.getUngroupedLots(this.buyerId, this.auctionClusterId, this.catalogId, moment(date).local().format('YYYY-MM-DD'), this.model.groupedIds).subscribe(result => {
        result.lots.rows.forEach(_ => {
          _.originalAmount = _.amount;
          _.allOrNothing = _.allOrNothing;
        });

        this.items = result.lots.rows;

        if (result.prebid) {
          this.prebid.allOrNothing = result.prebid.allOrNothing;

          let prebidSubbuyer = this.subbuyers.find(_ => _.subNumber === result.prebid.subNumber);
          if (prebidSubbuyer)
            this.prebid.subbuyerId = prebidSubbuyer.buyerId;
        }

        this.parseColumns(result.lots.columnTitles);
        this.parseGenericLots(result);
        this.isOpened = true;
    }, err => { 
    });

    if (this.prebid) {
      this.isEditMode = true;
    } else {
      this.prebid = new BuyerActionOnLotGroup();
      this.prebid.subNumber = 0;
      this.isEditMode = false;
    }

    this.prebid.buyerId = this.buyerId;
    this.prebid.catalogId = catalogId;
    this.prebid.groupedIds = this.model.groupedIds;
    this.prebid.allOrNothing = !allowPartialLotPrebid;
    this.prebid.allowPartialLotPrebid = allowPartialLotPrebid;

    // TODO: remove when BE adjusted for null price
    if (this.prebid.price === 0) {
      this.prebid.price = null;
    }
    this.prebidForAll = this.prebid.price > 0 || !allowPartialLotPrebid;
    //this.prebidForAll = false;
  }

  private parseGenericLots(result: GenericLots) { //rows: Array<GenericLotRow>) {
    this.data = [];

    // Apply predib/marked filter for lots
    // if (this.filterPrebids || this.filterMarked) {
    //   rows = rows.filter(_ => {
    //     return (this.filterPrebids && _.prebid) || (this.filterMarked && _.hasMark);
    //   });
    // }
    let fillAmountWanted = true;
    if (this.prebid) {
      fillAmountWanted = this.prebid.amountWanted === 0 || this.prebid.amountWanted === undefined;

      if (fillAmountWanted)
        this.prebid.amountWanted = 0;
    }

      result.lots.rows.forEach(lot => {

        let lotPrebids: Array<BuyerActionOnLot> = [];
  
        if (result.prebid) {
          lotPrebids = result.prebid.buyerActionOnLot.filter(_ => _.lotId === lot.id);
        }
  
        if (fillAmountWanted) {
          this.prebid.amountWanted += lot.amount;
        }
  
        if (lotPrebids.length !== 0 && this.allowMultiplePrebidsPerBuyerOnSameLot) {
          // Parse partial prebids
          let sum = 0;
  
          var anyPriceWanted = false;
  
          lotPrebids.forEach((prebid, index) => {
  
            let obj = this.parseLot(lot);
            obj.__item__.allOrNothing = prebid.allOrNothing;
            if (prebid.amountWanted === 0 || prebid.amountWanted === null) {
              prebid.amountWanted = obj.__item__.originalAmount;
            }
            obj.__item__.buyerActionOnLotId = prebid.buyerActionOnLotId;
            obj.__item__.amount = prebid.amountWanted;
            obj.__item__.originalAmount = prebid.amountWanted;
            obj.__item__.price = prebid.price;
            obj.__item__.subNumber = prebid.subNumber;
            obj.__item__.subbuyerId = prebid.subbuyerId;
            obj.__item__.maxAmount = prebid.maxAmount;
            obj.__item__.canRemove = index !== 0;
  
            if (prebid.price > 0) {
              anyPriceWanted = true;
            }
  
            this.data.push(obj);
  
            sum += prebid.amountWanted;
          });
  
          if (sum < lot.amount && anyPriceWanted) {
            let obj = this.parseLot(lot);
            obj.__item__.allOrNothing = false;
            obj.__item__.amount = lot.amount - sum;
            obj.__item__.originalAmount = lot.amount - sum;
            obj.__item__.price = null;
            obj.__item__.subNumber = this.subbuyers[0].subNumber;
            obj.__item__.subbuyerId = this.subbuyers[0].buyerId;
            obj.__item__.maxAmount = null;
            obj.__item__.canRemove = lotPrebids.length !== 0;
  
            this.data.push(obj);
          }
        }
        else {
          if (!this.data.find(_ => _['__item__'].id === lot.id)) {
            let obj = this.parseLot(lot);
  
            if (lotPrebids.length !== 0) {
              obj.__item__.buyerActionOnLotId = lotPrebids[0].buyerActionOnLotId;
              obj.__item__.amount = lotPrebids[0].amountWanted;
              obj.__item__.price = lotPrebids[0].price;
              obj.__item__.allOrNothing = lotPrebids[0].allOrNothing;
              obj.__item__.subNumber = lotPrebids[0].subNumber;
              obj.__item__.subbuyerId = lotPrebids[0].subbuyerId;
              obj.__item__.maxAmount = lotPrebids[0].maxAmount;
            }
            if (!obj.__item__.subNumber) {
              obj.__item__.subNumber = this.subbuyers[0].subNumber
            }
            if (!obj.__item__.subbuyerId) {
              obj.__item__.subbuyerId = this.subbuyers[0].buyerId;
            }
            if (!obj.__item__.maxAmount) {
              obj.__item__.maxAmount = 0;
            }
  
            this.data.push(obj);
          }
        }
      });

    this.data.sort((a, b) => {
      return a.__item__.id - b.__item__.id;
    });

    this.generateTable();
    // Reset default page size
    // this.currentPage = this.lastRecordedPage;
    // this.setPageSize(this.pageSizeValue);
  }

  parseLot(lot: GenericLotRow): any {
    const obj = new Object();
    this.columns.forEach((column, i) => {
      // Parse prebids
      //if (row.prebid) {
      //  // Add prebid price in values
      //  row.values.push(row.prebid.price.toFixed(2).toString());
      //  // Find and add subbuyer in values
      //  const subbuyer = this.subbuyers.find(_ => _.buyerId === row.prebid.subbuyerId);
      //  if (subbuyer) {
      //    row.values.push(`${subbuyer.subNumber} - ${subbuyer.name}`);
      //  } else {
      //    // Add null if there is no subbuyer
      //    row.values.push(null);
      //  }
      //}

      if (column.propertyTypeId === ProductPropertyTypeEnum.DATE) {
        obj['key' + i] = this.datetime.getDateStringByFormatAnyUtc(lot.values[i], column.propertyTypeFormatId);
      } else if (column.propertyTypeId === ProductPropertyTypeEnum.URL) {
        obj['key' + i] = lot.values[i];
      } else if (column.propertyTypeId === ProductPropertyTypeEnum.TEXT
        || column.propertyTypeId === ProductPropertyTypeEnum.BOOLEAN
        || column.propertyTypeId === ProductPropertyTypeEnum.IMAGE
        || column.propertyTypeId === ProductPropertyTypeEnum.MASTER_DATA) {
        // Add line breaks for string value
        let objValue = this.getTranslation(lot.values[i]);
        objValue = this.addLineBreaks(objValue);
        obj['key' + i] = objValue;
      } else if (isNaN(Number(lot.values[i]))) {
        let objValue = this.getTranslation(lot.values[i]);
        objValue = this.addLineBreaks(objValue);
        obj['key' + i] = objValue;
      } else {
        obj['key' + i] = Number(lot.values[i]);
      }
    });
    obj['__item__'] = JSON.parse(JSON.stringify(lot));

    return obj;
  }

  private parseColumns(columns: Array<GenericLotColumn>) {
    const _columns = [];
    columns.forEach(column => {
      _columns.push({
        name: this.getTranslation(column.name),
        propertyTypeId: column.propertyTypeId,
        propertyTypeFormatId: column.propertyTypeFormatId
      });
    });

    // Additional columns for displaying prebid info
    // _columns.push({
    //   name: this.getTranslation(this.translations.PREBID_PRICE),
    //   propertyTypeId: null
    // });
    // _columns.push({
    //   name: this.getTranslation(this.translations.PREBID_SUBBUYER),
    //   propertyTypeId: null
    // });


    const _objectKeys = [];
    _columns.forEach((column, i) => {
      _objectKeys.push('key' + i);
    });

    this.objectKeys = _objectKeys;
    this.columns = _columns;
  }

  parseSinglePrebids() {
    this.prebid.buyerActionOnLot = [];
    this.data.forEach(r => {
      var lotsOfSameId = this.data.filter(_ => { _['__item__'].id === r['__item__'].id });

      if (r['__item__'] && r['__item__'].price > 0 || lotsOfSameId.length === 1) {
        const buyerActionOnLot = new BuyerActionOnLot();
        buyerActionOnLot.buyerActionOnLotId = r['__item__'].buyerActionOnLotId ? r['__item__'].buyerActionOnLotId : 0;
        buyerActionOnLot.subNumber = r['__item__'].subNumber ? r['__item__'].subNumber : 0;
        buyerActionOnLot.subbuyerId = r['__item__'].subbuyerId ? r['__item__'].subbuyerId : 0;
        buyerActionOnLot.amountWanted = r['__item__'].amount;
        buyerActionOnLot.price = r['__item__'].price;
        buyerActionOnLot.lotId = r['__item__'].id;
        buyerActionOnLot.maxAmount = r['__item__'].maxAmount;
        buyerActionOnLot.allOrNothing = r['__item__'].allOrNothing;
        let subbuyer = this.subbuyers.find(s => s.subNumber === buyerActionOnLot.subNumber);
        if (subbuyer) {
          buyerActionOnLot.subbuyerId = subbuyer.buyerId;
        }

        this.prebid.buyerActionOnLot.push(buyerActionOnLot);
      }
    });
  }

  resetSinglePrebids(resetAllOrNothing: boolean = false) {
    this.data.forEach(r => {
      if (!resetAllOrNothing) {
        r['__item__'].price = null;
        r['__item__'].amountWanted = null;
        r['__item__'].subNumber = null;
        r['__item__'].subbuyerId = null;
      }
      else {
        r['__item__'].allOrNothing = false;
      }
    });
  }

  resetPrebid() {
    this.prebid.price = null;
    this.prebid.subNumber = null;
    this.prebid.subbuyerId = null;
    this.prebid.amountWanted = null;
  }

  maxAmountChanged(e: any, lot: GenericLotRow)
  {
    if(e && e.event !== undefined && e.event.isTrusted)
    {
      if(e.value < lot.amount)
      {
        lot.amount = e.value;
      }
    }

    
  }

  lotAmountChanged(lot: GenericLotRow, e: any) {
    if (!this.allowPartialLotPrebid) {
      lot.amount = lot.originalAmount;
      return;
    }

    // Check if selected amount must be of box content multitude
    if (lot.amount !== 0 && lot.amount !== lot.originalAmount && lot.amountInBox !== 0 && this.amountIsBoxContentMultitude) {
      let restLot = lot.originalAmount % lot.amountInBox;
      let rest = lot.amount % lot.amountInBox;
      let fullBoxes = Math.floor(lot.amount / lot.amountInBox);

      if (rest !== 0) {
        if (lot.amount >= lot.amountInBox * fullBoxes + restLot) {
          lot.amount = (lot.amountInBox * fullBoxes) + restLot;
        }
        else {
          lot.amount = lot.amountInBox * fullBoxes;
        }
      }
    }
    
    if(e != null && e.event !== undefined && e.event.isTrusted && e.value > lot.maxAmount)
    {
      lot.maxAmount = e.value;
    }

    if (lot.amount > lot.originalAmount) {
      lot.amount = lot.originalAmount;
      lot.maxAmount = lot.amount;
      
    } 
    else if(e != null && e.event !== undefined && e.event.isTrusted && lot.amount <= 0)
    {
      if(!this.removeSubLot(lot, null))
      {
        lot.amount = lot.originalAmount;
        lot.maxAmount = lot.amount;
      }
    }
    else if(lot.amount <= 0)
    {
      lot.amount = lot.originalAmount;
      lot.maxAmount = lot.amount;
      
    }
    else if (lot.amount < lot.originalAmount && this.allowMultiplePrebidsPerBuyerOnSameLot) {

      let originalAmount = lot.originalAmount;
      let amount = lot.amount;

      lot.originalAmount = amount;

      let subLot = JSON.parse(JSON.stringify(lot));
      subLot.__item__ = JSON.parse(JSON.stringify(lot));
      
      subLot.__item__.buyerActionOnLotId = null;
      subLot.__item__.hasMark = false;
      subLot.__item__.prebid = null;
      subLot.__item__.price = null;
      //subLot.selectedSubBuyerUniqueId = ko.observable(null);
      //subLot.prebidPrice = ko.observable(null);
      subLot.__item__.allOrNothing = false;
      subLot.__item__.amount = originalAmount - amount;
      subLot.__item__.originalAmount = subLot.__item__.amount;
      subLot.__item__.canRemove = true;
      if(subLot.__item__.maxAmount > 0)
      {
        subLot.__item__.maxAmount = subLot.__item__.amount;
      }

      this.data.push(subLot);

      //// Sort by lotId
      this.data.sort((a, b) => {
        return a.__item__.id - b.__item__.id;
      });

    }

    this.generateTable();
  }

  removeSubLot(lotObj: any, event: Event) {

    if (!lotObj.canRemove) {
      lotObj.price = null;
      lotObj.subbuyerId = null;
      return false;
    }

    let lotOriginal = this.data.find(d => d.__item__.id === lotObj.id);

    if (lotOriginal) {
      lotOriginal.__item__.amount = lotOriginal.__item__.amount + lotObj.originalAmount;
      lotOriginal.__item__.originalAmount = lotOriginal.__item__.amount;
      
      if(lotOriginal.__item__.maxAmount < lotOriginal.__item__.amount)
      {
        lotOriginal.__item__.maxAmount = lotOriginal.__item__.amount
      }
    }

    this.data = this.data.filter(d => d.__item__ !== lotObj);
    this.generateTable();

    return true;
  }

  removeGroupPrice() {
    this.prebid.price = null;
    this.prebidForAll = false;
  }

  save() {
    // Set subbuyerId in prebid object is subNumber is selected
    if (this.prebid.subNumber && this.prebid.subNumber != 0) {
      let subbuyer = this.subbuyers.find(s => s.subNumber === this.prebid.subNumber);
      if (subbuyer) {
        this.prebid.subbuyerId = subbuyer.buyerId;
      }
    } else {
      this.prebid.subNumber = 0;
      this.prebid.subbuyerId = null;
    }

    this.parseSinglePrebids();

    // TODO: allow prebid price to be null on BE
    if (this.prebid.price === null) {
      this.prebid.price = 0;
    }

    if (this.isEditMode) {
      this.spinner.show();
      this.dataService.editPrebid(this.prebid, this.auctionClusterId)
        .subscribe((res: any) => {
          this.prebid = new BuyerActionOnLotGroup();
          // this.detailsForm.reset();
          this.close(true);
          this.errorMessage = null;
          this.spinner.hide();
        },
          err => {
            this.saveErrorEvent.emit(err);
            this.errorService.show(err);
            this.spinner.hide();
            this.close(true);
          });
    } else {
      this.spinner.show();
      this.dataService.savePrebid(this.prebid, this.auctionClusterId)
        .subscribe((res: any) => {
          this.prebid = new BuyerActionOnLotGroup();
          // this.detailsForm.reset();
          this.close(true);
          this.errorMessage = null;
          this.spinner.hide();
        },
          err => {
            this.saveErrorEvent.emit(err);
            this.errorService.show(err);
            this.spinner.hide();
            this.close(true);
          });
    }
  }

  initColumn = (e: any) => {
    this.dataGridInstance = e.component;
  }

  generateTable = () => {
    const gridCurrentColums = this.dataGridInstance.getVisibleColumns();
    const items = [];
    const columnsArray = [];

    this.columns.forEach((row: any, i: number) => {
      let columnName = this.getTranslation(row.name);
      if (columnsArray.includes(columnName)) {
        let j = 0;
        do {
          columnName = this.getTranslation(row.name) + j;
          j++;
        } while (columnsArray.includes(columnName));
      }
      columnsArray.push(columnName);
      const isColumn = gridCurrentColums.find(c => c.dataField === columnName);

      if (!isColumn) {
        this.dataGridInstance.addColumn({
          dataField: columnName,
          caption: this.getTranslation(row.name),
          visibleIndex: i,
          allowEditing: false,
          encodeHtml: false,
          editorOptions: {
            showClearButton: true
          },
          cellTemplate: this.assignDefaultCellTemplate(row.propertyTypeId, row.propertyTypeFormatId)
        });
      }
    });

    this.data.forEach(row => {
      let item = {};
      var imageColumns =  this.columns.filter(tableColumn => tableColumn.propertyTypeId === ProductPropertyTypeEnum.URL && tableColumn.propertyTypeFormatId === UrlTypeEnum.IMAGE_URL);

      columnsArray.forEach((column, i) => {
        if (imageColumns.length !== 0 && imageColumns.filter(_ => _.name === column).length !== 0 && !row.__item__.canRemove) {
          item[column] = row['key' + i];
        } else {
          if (!row.__item__.canRemove) {
            item[column] = this.getTranslation(row['key' + i]);
          } else {
            item[column] = '';
          }
        }
      });

      item['__item__'] = row.__item__;

      items.push(item);
    });

    this.dataGridInstance.option('dataSource', items);
  }

  fieldName = (e: any) => {
    if (e !== null) {
      return e.subNumber + ' - ' + e.name;
    }
  }

  prebidSettings = (e: any) => {
    this.prebid.allOrNothing = false;
  }

  parseValue = (e: any) => {
    console.log(e);
  }

  prebidForAllChanged() {
    if (!this.prebidForAll) {
      //this.prebid.price = null;
      this.resetPrebid();
    }
    else{
      this.resetSinglePrebids();
    }
  }

  isDisabled() {
    this.parseSinglePrebids();

    var prebidOnTotalLot = this.prebidForAll && this.prebid.price && this.prebid.subbuyerId;
    var prebidOnSingleLot = !this.prebidForAll && this.prebid.buyerActionOnLot.some(_ => _.amountWanted && _.price && _.subbuyerId != 0);

    return !(prebidOnTotalLot || prebidOnSingleLot);
  }

  doesImageCalculationExist(data: any) {
    if (data && JSON.parse(data).find(el => el.field_0 !== '')) {
      return true;
    }
    return false;
  }

  getImage(data: any) {
    let imageUrl = JSON.parse(data).find(el => el.field_0 !== '').field_0;
    return imageUrl + '-thumbnail';
  }

  showLargerImage(data: string) {
    this.spinner.show();
    this.popUpOpened = true;

    this.largeImageSrc = JSON.parse(data).find(el => el.field_0 !== '').field_0;
    this.imageCaption = JSON.parse(data).find(el => el.field_0 !== '').field_1;

    if (this.imageLoaded) {
      this.spinner.hide();
      this.popUpShowed = true;
      this.imagePopUpShown = true;
    }
  }

  assignDefaultCellTemplate(propertyTypeId: ProductPropertyTypeEnum, propertyTypeFormatId?: UrlTypeEnum) {
    if (propertyTypeId === ProductPropertyTypeEnum.URL && propertyTypeFormatId === UrlTypeEnum.IMAGE_URL) {
      return 'imageCellTemplate';
    } else if (propertyTypeId === ProductPropertyTypeEnum.TEXT) {
      return 'textCellTemplate';
    } else {
      return 'cellTemplate';
    }
  }

  openPopUp() {
    this.spinner.hide();
    this.popUpShowed = true;
    this.imageLoaded = true;
    this.imagePopUpShown = true;
  }

  closePopUp() {
    this.popUpOpened = false;
    this.popUpShowed = false;
  }

  @HostListener('window:keydown', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    if (event.key === "Escape") {
      if (this.imagePopUpShown) {
        this.imagePopUpShown = false;
        return;
      } else {
        event.stopPropagation();
        this.isOpened = false;
      }
    }
  }
}
